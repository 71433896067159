.app {
  margin-top: 100px; // push app body below the nav bar
}

// Primary Colors
$ocean-blue: #016ea7;
$fire-orange: #f89732;
$leaf-green: #8fc640;

// Secondary Colors
$charcoal-gray: #282828;
$stone-gray: #bcbec0;
$lava-orange: #df5426;
$midnight-blue: #143055;
$forest-green: #1f8f45;

// replacing also bootstrap theme colors with PG colors
// https://getbootstrap.com/docs/4.4/getting-started/theming/#theme-colors
$theme-colors: (
  "primary": $ocean-blue,
  "secondary": $charcoal-gray,
  "success": $forest-green,
  "danger": $lava-orange,
  "warning": $fire-orange,
  "info": $leaf-green,
  "light": $stone-gray,
  "dark": $midnight-blue,
);

.Toastify__toast--success {
  background-color: $leaf-green !important;
}

.Toastify__toast--info {
  background-color: $stone-gray !important;
}

.Toastify__toast--warning {
  background-color: $fire-orange !important;
}

.Toastify__toast--error {
  background-color: $lava-orange !important;
}

.Toastify__toast--dark {
  background-color: $charcoal-gray !important;
}

@import "~bootstrap/scss/bootstrap";
@import "~react-toastify/scss/main";
